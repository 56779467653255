<nav>
    <ul>
        <li class="nav-item" routerLinkActive="active" [routerLink]="routeNames.main.HOME">
            {{'navigation.home' | translate}}
        </li>
        <li class="nav-item" routerLinkActive="active" [routerLink]="routeNames.main.JOBS_REPO">
            {{'navigation.jobs_repository' | translate}}
        </li>
        <li class="nav-item" routerLinkActive="active" [routerLink]="routeNames.main.SKILLS_REPO">
            {{'navigation.skills_repository' | translate}}
        </li>
        <li class="nav-item only-desktop" routerLinkActive="active" [routerLink]="routeNames.admin.HOME" *ngIf="isAdmin()">
            {{'navigation.admin' | translate}}
        </li>
    </ul>
</nav>
