import { Description } from './description';
import { Family } from './family';
import { Title } from './title';

// tslint:disable: variable-name
export class Level {
    id: number;
    family_id: number;
    level: number;
    active: boolean;
    title: Title;
    family: Family;
    description: Description;

    constructor(data = {}) {
        Object.assign(this, data);

        this.title = new Title(this.title);
        this.family = new Family(this.family);
        this.description = new Description(this.description);
    }
}
