
// tslint:disable: variable-name
export class Page {
    id: number;
    domain_id: number;
    language_id: number;
    title_1: string;
    section_1: string;
    section_2: string;
    title_2: string;
    section_3: string;
    section_4: string;
    title_3: string;
    section_5: string;
    section_6: string;
    section_7: string;
    section_8: string;
    section_9: string;
    section_10: string;
    active: boolean;

    constructor(data = {}) {
        Object.assign(this, data);
    }
}
