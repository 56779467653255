import { Position } from './position';
import { Title } from './title';

// tslint:disable: variable-name
export class Profession {
    id: number;
    code: string;
    domain_id: number;
    domain_code: string;
    active: number;
    title: Title;
    positions: Position[];

    constructor(data = {}) {
        Object.assign(this, data);

        this.title = new Title(this.title);
        this.positions = this.positions?.map(pos => new Position(pos));
    }
}
