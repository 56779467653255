import { Page } from './page';
import { Profession } from './profession';
import { Testimonial } from './testimonial';
import { Title } from './title';

export class Domain {
    id: number;
    code: string;
    active: number;
    title: Title;
    page: Page;
    professions: Profession[];
    testimonials?: Testimonial[];

    constructor(data = {}) {
        Object.assign(this, data);

        this.title = new Title(this.title);
        this.page = new Page(this.page);
        this.professions = this.professions?.map(prof => new Profession(prof));
    }
}
