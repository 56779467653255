import { DomainParent } from './domain-parent';
import { Level } from './level';
import { PositionSkillData } from './positionSkillData';

// tslint:disable: variable-name
export class PositionSkill {
    position_id: number;
    skill_id: number;
    domain_id: number;
    level_id: number;
    active: boolean;

    level: Level;
    domain: DomainParent;
    skill: PositionSkillData;



    constructor(data = {}) {
        Object.assign(this, data);

        this.level = new Level(this.level);
        this.domain = new DomainParent(this.domain);
        this.skill = new PositionSkillData(this.skill);
    }
}
