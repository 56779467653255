import { Description } from './description';
import { PositionSkill } from './positionSkill';
import { Title } from './title';

// tslint:disable: variable-name
export class Position {
    id: number;
    code: string;
    profession_id: number;
    active: number;
    title: Title;
    description: Description;
    skills: PositionSkill[];

    constructor(data = {}) {
        Object.assign(this, data);

        this.title = new Title(this.title);
        this.skills = this.skills?.map(skill => new PositionSkill(skill));
    }
}
