import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AuthService } from '@ff/auth';
import { UserService } from '@ff/user';
import { TranslateService } from '@ngx-translate/core';
import { CrudService } from './../../../../services/shared/crud.service';
import { LanguagesService } from './../../../../services/shared/languages.service';
import { SharedService } from './../../../../services/shared/shared.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Output() triggerMenu: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private authService: AuthService,
    private crudService: CrudService,
    private sharedService: SharedService,
    private translateService: TranslateService,
    private languagesService: LanguagesService,
    public userService: UserService
  ) {
  }

  get langs() {
    return this.languagesService.langs;
  }

  get currentLang() {
    return this.translateService.currentLang;
  }

  set currentLang(lang: string) {
    this.translateService.use(lang);
    this.translateService.currentLang = lang;
  }

  ngOnInit() {
  }

  toggleNav() {
    this.triggerMenu.emit();
  }

  updateLang(lang: string): void {
    if (this.authService.isLoggedIn().getValue()) {
      this.crudService.updateUserLang(lang).subscribe(user => {
        this.userService.getUser().next(user);
        this.translateService.use(lang);
        this.translateService.currentLang = lang;
      });
    }
  }

  get routeNames() {
    return this.sharedService.routeNames();
  }
}
