import { Component, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { ATInternetService } from '@ff/at-internet';
import { AuthService } from '@ff/auth';
import { UserService } from '@ff/user';
import { TranslateService } from '@ngx-translate/core';
import { CrudService } from 'src/app/services/shared/crud.service';
import { LanguagesService } from 'src/app/services/shared/languages.service';
import { SharedService } from 'src/app/services/shared/shared.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  @ViewChild('nav') nav?: MatSidenav;

  public constructor(
    private translate: TranslateService,
    private atInternetService: ATInternetService, // PLEASE don't remove, it is needed for Xiti to work
    private authService: AuthService,
    public userService: UserService,
    private languagesService: LanguagesService,
    private sharedService: SharedService,
    private crudService: CrudService,
  ) {
    this.initLang(translate);
  }

  get langs() {
    return this.languagesService.langs;
  }

  get currentLang() {
    return this.translate.currentLang;
  }

  set currentLang(lang: string) {
    this.translate.use(lang);
    this.translate.currentLang = lang;
  }

  toggleNav(): void {
    this.nav.toggle();
  }

  onActivate(event) {
    window.scroll(0, 0);
  }

  get routeNames() {
    return this.sharedService.routeNames();
  }

  updateLang(lang: string): void {
    if (this.authService.isLoggedIn().getValue()) {
      this.crudService.updateUserLang(lang).subscribe(user => {
        this.userService.getUser().next(user);
        this.translate.use(lang);
        this.translate.currentLang = lang;
      });
    }
  }


  private initLang(translate: TranslateService) {
    let currentLang = 'en';
    const translatorLang = this.translate.currentLang;

    if (translatorLang) {
      currentLang = translatorLang;
    }

    if (this.authService.isLoggedIn().getValue()) {
      const user = this.userService.getUser().getValue();
      currentLang = user?.language_code;
    }

    this.translate.setDefaultLang(currentLang);
    translate.use(currentLang);
  }
}
