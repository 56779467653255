export const mainRoutesNames = {
    ROOT: '',
    DOMAIN: 'domain',
    HOME: 'home',
    JOBS_REPO: 'jobs-repository',
    POSITION: 'position',
    SEARCH_RESULTS: 'search-resuts',
    SKILL: 'skill',
    SKILLS_REPO: 'skills-repository',
};
