<mat-sidenav-container class="h-100">
  <mat-sidenav #nav mode="over" *ngIf="userService.getUser() | async">
    <app-navigation></app-navigation>

    <div class="px-3">
      <div *ngIf="userService.getUser() | async" class="d-flex mr-3 pointer" [matMenuTriggerFor]="userMenu">
        <span class="username mr-2">
          {{userService.getUser().value.last_name + ' ' + userService.getUser().value.first_name }}
        </span>
        <mat-icon color="primary" class="align-self-center">account_circle</mat-icon>
      </div>
      <mat-menu class="mt-2" #userMenu="matMenu" [overlapTrigger]="false">
        <a mat-menu-item [routerLink]="routeNames.auth.LOGOUT">{{'auth.logout' | translate}}</a>
      </mat-menu>

      <mat-select [(ngModel)]="currentLang" name="currentLang" (selectionChange)="updateLang(currentLang)"
        class="lang-select">
        <mat-option *ngFor="let lang of langs" [value]="lang.code">
          {{lang.code.toUpperCase()}}
        </mat-option>
      </mat-select>
    </div>
  </mat-sidenav>
  <mat-sidenav-content class="d-flex flex-column">
    <app-header (triggerMenu)="toggleNav()"></app-header>
    <main>
      <router-outlet (activate)="onActivate($event)"></router-outlet>
    </main>
    <app-cookies-banner *ngIf="userService.getUser() | async">
    </app-cookies-banner>
    <app-footer *ngIf="userService.getUser() | async"></app-footer>
  </mat-sidenav-content>
</mat-sidenav-container>