import { SharedService } from './../../../../services/shared/shared.service';
import { Component, OnInit } from '@angular/core';
import { UserService } from '@ff/user';
import { adminRoutesNames } from './../../../admin/admin.routes.names';
import { mainRoutesNames } from './../../../main/main.routes.names';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  constructor(
    private sharedService: SharedService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
  }

  isAdmin() {
    return (this.userService.getUser().value.role as string).toLowerCase() === 'admin';
  }

  get routeNames() {
    return this.sharedService.routeNames();
  }
}
