import { Component, OnInit } from '@angular/core';
import { UserService } from '@ff/user';
import { CrudService } from './../../../../services/shared/crud.service';

@Component({
  selector: 'app-cookies-banner',
  templateUrl: './cookies-banner.component.html',
  styleUrls: ['./cookies-banner.component.scss']
})
export class CookiesBannerComponent implements OnInit {

  constructor(
    private userService: UserService,
    private crudService: CrudService
  ) { }

  ngOnInit(): void {
  }

  displayBanner() {
    return !this.userService.getUser()?.getValue()?.has_cookies;
  }


  agreeCookies() {
    this.crudService.agreeCookies().subscribe((res: any) => {
      // Nothing to do
      this.userService.getUser().next(res.user);
    });
  }
}
