<ng-container *ngIf="displayBanner()">

    <div class="banner-container">
        <p class="banner-text">
            {{'cookies.description' | translate}}
            <!-- TODO: link -->
            <a href="http://" class="banner-link ml-2">{{'cookies.link' | translate}}</a>
        </p>
        <button mat-flat-button color="primary" (click)="agreeCookies()">{{'cookies.agree' | translate}}</button>
    </div>

</ng-container>
