import { Domain } from './domain';
import { Level } from './level';
import { Position } from './position';
import { Profession } from './profession';

// tslint:disable: variable-name
export class SkillPosition {

    position_id: number;
    skill_id: number;
    domain_id: number;
    level_id: number;
    active: boolean;
    level: Level;
    domain: Domain;
    position: Position;
    profession: Profession;


    constructor(data = {}) {
        Object.assign(this, data);

        this.level = new Level(this.level);
        this.domain = new Domain(this.domain);
        this.position = new Position(this.position);
        this.profession = new Profession(this.profession);
    }
}
