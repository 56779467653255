export const endpoints = {
  agreeCookies: '/user/agreeCookies',
  domain: '/domain',
  domains: '/domains',
  testimonials: '/testimonials',
  getDocument: '/document',
  getDocumentExtension: '/document/extension',
  languages: '/languages',
  langUpdate: '/user/language',
  position: '/position',
  profession: '/profession',
  professions: '/professions',
  positions: '/positions',
  randomDomain: '/randomDomain',
  search: '/search',
  skill: '/skill',
  validateRecaptcha: '/user/validateRecaptcha',
  incrementVideo: '/admin/increment/video',
};
