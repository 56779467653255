<footer class="mt-5">
    <div class="footer-container">
        <div class="box-size">
            <img src="assets/images/logo_skills.jpg" alt="Logo Total Skills" height="65" class="mx-2" />
        </div>
        <div class="box-size">
            <p>{{'footer.hru_coords' | translate}}</p>
            <span class="ml-1 d-flex align-items-center">
                <mat-icon color="primary">arrow_right</mat-icon>
                <a class="link" href="https://hr4u.main.glb.corp.local/"
                    target="_blank">{{'footer.hru_coords_link' |translate}}</a>
            </span>
        </div>
        <div class="mb-0 box-size">
            <p class="font-weight-bold">{{'footer.admin_contact' | translate}}</p>
            <a [href]="'mailto:' + ('footer.admin_email' | translate) + '?subject=' + ('footer.admin_contact_subject' | translate)"
                class="email-link" target="_blank">
                <mat-icon class="email-icon">email</mat-icon>
                <span>{{'footer.admin_email' | translate}}</span>
            </a>
        </div>
    </div>
</footer>