
// tslint:disable: variable-name
export class SkillType {

    id: number;
    name: string;
    active: boolean;

    constructor(data = {}) {
        Object.assign(this, data);
    }
}
