import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from '@ff/auth';
import { Observable } from 'rxjs';
import { SharedService } from './shared.service';

@Injectable({
  providedIn: 'root'
})
export class RmcLoggedInGuard implements CanActivate {

  constructor(
    public authService: AuthService,
    private sharedService: SharedService,
    private router: Router) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    try {
      const currentUser = this.authService.isLoggedIn().getValue();

      if (currentUser) {
        return true;
      } else {
        this.gotoLogin();
      }
    } catch (error) {
      this.gotoLogin();
    }
  }

  private gotoLogin() {
    this.router.navigate([this.sharedService.routeNames().auth.LOGIN]);

  }
}
