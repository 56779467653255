// tslint:disable: variable-name
export class Title {
    id: number;
    type: string;
    entity_id: number;
    language_id: number;
    title: string;
    active: boolean;

    constructor(data = {}) {
        Object.assign(this, data);
    }
}
