
// tslint:disable: variable-name
export class Description {
    id: number;
    type: string;
    entity_id: number;
    level_id: number;
    language_id: number;
    description: string;
    active: boolean;

    constructor(data = {}) {
        Object.assign(this, data);
    }
}
