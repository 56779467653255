import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { authRoutesNames } from 'src/app/modules/auth/auth.routes.names';
import { adminRoutesNames } from './../../modules/admin/admin.routes.names';
import { coreRoutesNames } from './../../modules/core/core.routes.names';
import { mainRoutesNames } from './../../modules/main/main.routes.names';
import { LanguagesService } from './languages.service';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor(
    private translateService: TranslateService,
    private languagesService: LanguagesService
  ) { }

  getCurrentLangId() {
    if (this.languagesService.langs.length > 0) {
      return this.languagesService.langs.find(lang => lang.code === this.translateService.currentLang).id;
    } else {
      // Should not happen since we get langs in APP_INITIALIZER token
      return 2;
    }
  }

  routeNames() {
    const routes = { main: { ...mainRoutesNames }, admin: { ...adminRoutesNames }, auth: { ...authRoutesNames } };

    Object.keys(mainRoutesNames).forEach(key => {
      routes.main[key] = '/' + coreRoutesNames.MAIN + '/' + mainRoutesNames[key];
    });
    Object.keys(adminRoutesNames).forEach(key => {
      routes.admin[key] = '/' + coreRoutesNames.ADMIN + '/' + adminRoutesNames[key];
    });
    Object.keys(authRoutesNames).forEach(key => {
      routes.auth[key] = '/' + coreRoutesNames.AUTH + '/' + authRoutesNames[key];
    });

    return routes;
  }
}
