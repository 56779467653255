import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from '@ff/auth';
import { UserService } from '@ff/user';
import { Observable } from 'rxjs';
import { SharedService } from './shared.service';

@Injectable({
  providedIn: 'root'
})
export class RmcAdminGuard implements CanActivate {

  constructor(
    public authService: AuthService,
    private userService: UserService,
    private sharedService: SharedService,
    private router: Router) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    try {
      const isLoggedIn = this.authService.isLoggedIn().getValue();

      if (isLoggedIn) {
        const isAdmin = (this.userService.getUser().getValue().role as string).toLowerCase() === 'admin';
        if (isAdmin) {
          return true;
        } else {
          this.gotoLogin();
        }
      } else {
        this.gotoLogin();
      }
    } catch (error) {
      this.gotoLogin();
    }
  }

  private gotoLogin() {
    this.router.navigate([this.sharedService.routeNames().auth.LOGIN]);
  }
}
