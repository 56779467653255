export class Testimonial {
    description: string;
    entity_code: string;
    id: number;
    language_id: number;
    nbViews: number;
    title: string;
    type: string;
    url: string;
    order: number;

    constructor(data = {}) {
        Object.assign(this, data);
    }
}