import { LanguagesService } from './../../services/shared/languages.service';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, Optional, SkipSelf, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, RouterModule } from '@angular/router';
import { API_CONFIG } from '@ff/api';
import { ATInternetModule, ATInternetService, AT_INTERNET_CONFIG } from '@ff/at-internet';
import { AuthModule, AUTH_CONFIG } from '@ff/auth';
import { OAuth2Module, OAUTH2_CONFIG } from '@ff/oauth2';
import { USER_CONFIG } from '@ff/user';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxCaptchaModule } from 'ngx-captcha';
import { environment } from 'src/environments/environment';
import { SharedModule } from '../shared/shared.module';
import { AppComponent } from './components/app/app.component';
import { CookiesBannerComponent } from './components/cookies-banner/cookies-banner.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { routes } from './core.routes';
import { ConfirmationDialogComponent } from './components/confirmation/confirmation-dialog.component';
import { UnauthorizedInterceptor } from 'src/app/services/shared/unauthorized.interceptor';

export function languagesServiceFactory(languagesService: LanguagesService) {
  return () => languagesService.load();
}

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'top',
  anchorScrolling: 'enabled',
  scrollOffset: [0, 64],
};
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    NavigationComponent,
    CookiesBannerComponent,
    ConfirmationDialogComponent
  ],
  providers: [
    {
      provide: API_CONFIG,
      useValue: {
        host: environment.api.host
      }
    },
    {
      provide: USER_CONFIG,
      useValue: {
        host: environment.api.host
      }
    },
    {
      provide: AUTH_CONFIG,
      useValue: {
        host: environment.api.host
      }
    },
    {
      provide: OAUTH2_CONFIG,
      useValue: {
        host: environment.api.host,
        endpoints: {
          bearer: '/oauth2/token',
          providers: '/oauth2/providers'
        }
      }
    },
    ATInternetService,
    {
      provide: AT_INTERNET_CONFIG,
      useValue: {
        site: 614846,
        autosend: true,
        except: [], // Do not send automatic hits for these routes
        secure: true, // Force HTTPS
        collectDomain: 'logc187.xiti.com', // Data collection domain
        collectDomainSSL: 'logs1187.xiti.com', // Secured data collection domain
      }
    },
    LanguagesService,
    { provide: APP_INITIALIZER, useFactory: languagesServiceFactory, deps: [LanguagesService], multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthorizedInterceptor,
      multi: true,
    }
  ],
  imports: [
    // Angular
    BrowserModule,
    BrowserAnimationsModule,

    // FF packages
    AuthModule,
    ATInternetModule,
    OAuth2Module,

    // Others
    RouterModule.forRoot(routes, routerOptions
      // { enableTracing: true } // <-- debugging purposes only
    ),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    SharedModule,

    // App packages
  ],
  bootstrap: [
    AppComponent
  ],
  entryComponents: [
    ConfirmationDialogComponent
  ]
})

export class CoreModule {
  public constructor(@Optional() @SkipSelf() parent: CoreModule) {
    if (parent) {
      throw new Error('CoreModule should not be exported then imported by another module!');
    }
  }
}

