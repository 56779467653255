import { Title } from './title';

// tslint:disable: variable-name
export class RandomDomain {
    id: number;
    code: string;
    active: number;
    title: Title;
    count_professions: number;
    count_positions: number;

    constructor(data = {}) {
        Object.assign(this, data);
        this.title = new Title(this.title);
    }
}
