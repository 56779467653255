<header>
  <div class="header-left">
    <a [routerLink]="routeNames.main.HOME" class="home-link">
      <img src="assets/logo.svg" title="TotalEnergies" alt="TotalEnergies" class="logo" height="76" />
    </a>
  </div>
  <div class="header-right only-desktop">
    <div class="header-right-top">
      <div class="app-name">
        <a [routerLink]="routeNames.main.HOME" class="home-link">
          <img width="80px" class="app-logo" src="/assets/images/logo_skills.jpg" alt="Logo Total Skills" />
        </a>
      </div>

      <div class="d-flex align-items-center">
        <div *ngIf="userService.getUser() | async" class="d-flex mr-3 pointer" [matMenuTriggerFor]="userMenu">
          <span class="username mr-2 only-desktop">
            {{userService.getUser().value.last_name + ' ' + userService.getUser().value.first_name }}
          </span>
          <mat-icon color="primary" class="align-self-center">account_circle</mat-icon>
        </div>
        <mat-menu class="mt-2" #userMenu="matMenu" [overlapTrigger]="false">
          <a mat-menu-item [routerLink]="routeNames.auth.LOGOUT">{{'auth.logout' | translate}}</a>
        </mat-menu>

        <mat-select [(ngModel)]="currentLang" name="currentLang" (selectionChange)="updateLang(currentLang)"
          class="lang-select">
          <mat-option *ngFor="let lang of langs" [value]="lang.code">
            {{lang.code.toUpperCase()}}
          </mat-option>
        </mat-select>
      </div>
    </div>
    <div class="header-right-bottom">
      <app-navigation *ngIf="userService.getUser() | async"></app-navigation>
    </div>
  </div>
  <div *ngIf="userService.getUser() | async" class="only-mobile">
    <button mat-icon-button (click)="toggleNav()"><mat-icon>menu</mat-icon></button>
  </div>
</header>
