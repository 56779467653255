<div mat-dialog-title>
  <h2 class="pt-0">{{data.title | translate}}</h2>
</div>
<div mat-dialog-content>
<p>{{data.message|translate:{value: data.value} }}</p>
</div>

<div mat-dialog-actions class="d-flex justify-content-end mt-3">
  <button mat-flat-button type="button" (click)="onNoClick()">
    <span>{{'general.cancel' | translate}}</span>
  </button>
  <button mat-flat-button color="primary" type="button" (click)="confirm()">
    <span>{{'general.confirm' | translate}}</span>
  </button>
</div>
