import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { endpoints } from './../../../environments/endpoints';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LanguagesService {

  langs: any[];

  constructor(private http: HttpClient) { }

  load(): Promise<any> {
    return new Promise<any[]>((resolve, reject) => {
      return this.http.get(environment.api.host + endpoints.languages).pipe(
        map((res: any) => res.data.languages),
        catchError(this.handleError)
      ).subscribe(langs => {
        this.langs = langs;
        resolve(langs);
      });
    });
  }

  protected handleError(error: any): Promise<any> {
    console.log('error', error);
    if (error.status === 401) {
      window.location.reload();
    }
    return Promise.reject(error?.error?.message || error?.message || error);
  }
}
