import { Routes } from '@angular/router';
import { RmcAdminGuard } from './../../services/shared/rmc-admin.guard';
import { RmcLoggedInGuard } from './../../services/shared/rmc-logged-in.guard';
import { authRoutesNames } from './../auth/auth.routes.names';
import { coreRoutesNames } from './core.routes.names';

export const routes: Routes = [
  {
    path: coreRoutesNames.MAIN,
    loadChildren: () => import('../main/main.module')
      .then(m => m.MainModule),
    canActivate: [RmcLoggedInGuard]
  },
  {
    path: coreRoutesNames.AUTH,
    loadChildren: () => import('../auth/auth.module')
      .then(m => m.AuthModule)
  },
  {
    path: coreRoutesNames.ADMIN,
    loadChildren: () => import('../admin/admin.module')
      .then(m => m.AdminModule),
    canActivate: [RmcAdminGuard]
  },
  { path: '**', redirectTo: coreRoutesNames.AUTH + '/' + authRoutesNames.LOGIN, pathMatch: 'full' }
];
