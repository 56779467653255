import { Description } from './description';
import { Family } from './family';
import { Level } from './level';
import { SkillPosition } from './skillPosition';
import { SkillSection } from './skillSection';
import { SkillType } from './skillType';
import { Title } from './title';

// tslint:disable: variable-name
export class Skill {
    id: number;
    family_id: number;
    code: string;
    type_id: number;
    section_id: number;
    active: boolean;
    title: Title;

    skill_id: number;
    domain_id: number;
    level_id: number;
    description: Description;

    available_levels: Level[];
    positions: SkillPosition[];
    family: Family;
    type: SkillType;
    section: SkillSection;



    constructor(data = {}) {
        Object.assign(this, data);

        this.description = new Description(this.description);
        this.family = new Family(this.family);
        this.type = new SkillType(this.type);
        this.section = new SkillSection(this.section);

        this.available_levels = this.available_levels?.map(level => new Level(level));
        this.positions = this.positions?.map(position => new SkillPosition(position));
    }
}
