import { UserService } from '@ff/user';
import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
  constructor(
    private userService: UserService
  ) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        (response: HttpResponse<any>) => {
          if (response.status === 200 && this.userService.getUser().getValue()) {
            const redirectUrl = localStorage.getItem('redirectUrl');
            if (redirectUrl) {
              window.location.href = redirectUrl;
              localStorage.removeItem('redirectUrl');
            }
          }
        },
        (error: HttpErrorResponse) => {
          if (error.status === 401) {
            const redirectUrl = window.location.href;
            if (!redirectUrl.includes('logout') && !redirectUrl.includes('login') && !redirectUrl.includes('app/home')) {
              localStorage.setItem('redirectUrl', redirectUrl);
            }
          }
        }
      )
    );
  }
}
