import { Title } from './title';

export class DomainParent {
    id: number;
    code: string;
    active: number;
    title: Title;

    constructor(data = {}) {
        Object.assign(this, data);

        this.title = new Title(this.title);
    }
}
