import { Title } from './title';

// tslint:disable: variable-name
export class PositionSkillData {

    id: number;
    family_id: number;
    code: string;
    type_id: number;
    section_id: number;
    active: boolean;
    title: Title;

    constructor(data = {}) {
        Object.assign(this, data);
        this.title = new Title(this.title);
    }
}
