// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  api: {
    host: 'https://api-rmc.mpsprod.alzp.tgscloud.net'
  },
  production: true,
  google: {
    siteKey: '6LfL_NgZAAAAAC2J9T_AqseEqAAFcat2ilwvOx_a'
  },
  jobPostingLinks: {
    base_url: 'https://jobposting.totalenergies.com',
  }
};
