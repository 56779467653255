import { Testimonial } from 'src/app/models/testimonial';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { endpoints } from 'src/environments/endpoints';
import { environment } from 'src/environments/environment';
import { Skill } from '../../models/skill';
import { Domain } from './../../models/domain';
import { Position } from './../../models/position';
import { Profession } from './../../models/profession';
import { RandomDomain } from './../../models/randomDomain';
import { SharedService } from './shared.service';

@Injectable({
  providedIn: 'root'
})
export class CrudService {

  constructor(
    private http: HttpClient,
    private sharedService: SharedService
  ) {
  }

  getRandomDomain(): Observable<RandomDomain> {
    const idLang = this.getCurrentLangId();
    return this.http.get(environment.api.host + endpoints.randomDomain + '/' + idLang).pipe(
      map(
        (res: any) => new RandomDomain(res.data)
      ),
      catchError(this.handleError)
    );
  }

  getDomain(id: number): Observable<Domain> {
    const idLang = this.getCurrentLangId();
    return this.http.get(environment.api.host + endpoints.domain + '/' + idLang + '/' + id).pipe(
      map(
        (res: any) => new Domain(res.data)
      ),
      catchError(this.handleError)
    );
  }

  getDomains(): Observable<Domain[]> {
    const idLang = this.getCurrentLangId();
    return this.http.get(environment.api.host + endpoints.domains + '/' + idLang).pipe(
      map(
        (res: any) => res.data.map(item => new Domain(item))
      ),
      catchError(this.handleError)
    );
  }

  getTestimonialsFor(type: string, code?: string, idLang?: number): Observable<Testimonial[]> {
    const url = code ?
                    (idLang ? '/' + type + '/' + code + '/' + idLang : '/' + type + '/' + code) :
                    '/' + type;

    return this.http.get(environment.api.host + endpoints.testimonials + url).pipe(
      map(
        (res: any) => res.data.map(item => new Testimonial(item))
      ),
      catchError(this.handleError)
    );
  }

  getPosition(code: string): Observable<Position> {
    const idLang = this.getCurrentLangId();
    return this.http.get(environment.api.host + endpoints.position + '/' + idLang + '/' + code).pipe(
      map(
        (res: any) => new Position(res.data)
      ),
      catchError(this.handleError)
    );
  }

  getProfession(id: number): Observable<Profession> {
    const idLang = this.getCurrentLangId();
    return this.http.get(environment.api.host + endpoints.profession + '/' + idLang + '/' + id).pipe(
      map(
        (res: any) => new Profession(res.data)
      ),
      catchError(this.handleError)
    );
  }

  getPositions(): Observable<Position[]> {
    const idLang = this.getCurrentLangId();
    return this.http.get(environment.api.host + endpoints.positions + '/' + idLang).pipe(
      map(
        (res: any) => res.data.map(item => new Position(item))
      ),
      catchError(this.handleError)
    );
  }

  getSkill(id: number): Observable<Skill> {
    const idLang = this.getCurrentLangId();
    return this.http.get(environment.api.host + endpoints.skill + '/' + idLang + '/' + id).pipe(
      map(
        (res: any) => new Skill(res.data)
      ),
      catchError(this.handleError)
    );
  }

  search(encodedSearchValue: string): Observable<any[]> {
    const idLang = this.getCurrentLangId();
    const searchTerm = encodedSearchValue;
    return this.http.get(
      environment.api.host + endpoints.search,
      {
        params: { term: searchTerm, lang_id: idLang.toString() }
      }).pipe(
        map(
          (res: any) => res.data
        ),
        catchError(this.handleError)
      );
  }

  updateUserLang(langCode: string): Observable<any> {
    return this.http.get(environment.api.host + endpoints.langUpdate + '/' + langCode).pipe(
      map(
        (res: any) => res.data.user
      ),
      catchError(this.handleError)
    );
  }

  getDocument(key: string): Observable<Blob> {
    const langId = this.getCurrentLangId();
    return this.http.get(environment.api.host + endpoints.getDocument + '/' + langId + '/' + key, {
      responseType: 'blob'
    }).pipe(
      map(
        (res: Blob) => res
      ),
      catchError(this.handleError)
    );
  }

  getDocumentExtension(key: string): Observable<any> {
    const langId = this.getCurrentLangId();
    return this.http.get(environment.api.host + endpoints.getDocumentExtension + '/' + langId + '/' + key).pipe(
      map(
        (res: any) => res.data
      ),
      catchError(this.handleError)
    );
  }

  agreeCookies(): Observable<any> {
    return this.http.get(environment.api.host + endpoints.agreeCookies).pipe(
      map((res: any) => res.data),
      catchError(this.handleError)
    );
  }

  protected handleError(error: any): Promise<any> {
    if (error.status === 401) {
      window.location.reload();
    }
    return Promise.reject(error?.error?.message || error?.message || error);
  }

  private getCurrentLangId() {
    return this.sharedService.getCurrentLangId();
  }
}
